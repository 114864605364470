import { useCallback, useEffect, useRef, useState } from 'react'

export interface ProgressTrigger<T> {
	type: T
	value: number
}

export interface ProgressReturn<T> {
	event(e: T, status: boolean | Promise<boolean>): void
	push(e: T): void

	value: number
}

export const useProgress = <T>(triggerTypes: ProgressTrigger<T>[]): ProgressReturn<T> => {
	const triggered = useRef<T[]>([])
	const queue = useRef<any[]>([])
	const [progress, setProgress] = useState(0)

	const getTrigger = useCallback(
		(type: T) => triggerTypes.find((t) => t.type === type)!,
		[triggerTypes]
	)

	const add = useCallback(
		(event) => {
			triggered.current.push(event)
			setProgress((cur) => (cur += getTrigger(event).value))
		},
		[getTrigger]
	)

	const off = useCallback(
		(event) => {
			triggered.current = triggered.current.filter((e) => e !== event)
			setProgress((cur) => (cur -= getTrigger(event).value))
		},
		[getTrigger]
	)

	/**
	 * TODO очищать стэк во время работы тиков, оптимизировать участок
	 */
	useEffect(() => {
		if (!queue.current.find(Boolean)) {
			queue.current = []
		}
	})

	const eventProcess = useCallback(
		async (event: any, status: boolean | Promise<boolean>) => {
			let result: boolean

			if (status instanceof Promise) {
				const indexInQueue = queue.current.push(event) - 1

				result = await status

				if (queue.current.indexOf(event) !== indexInQueue) {
					queue.current[indexInQueue] = null
					return
				}

				queue.current[indexInQueue] = null
			} else {
				result = status
			}

			if (result && !triggered.current.includes(event)) {
				add(event)
			}

			if (!result && triggered.current.includes(event)) {
				off(event)
			}
		},
		[add, off]
	)

	const push = useCallback(
		(event: T) => {
			if (!triggered.current.includes(event)) {
				add(event)
			}
		},
		[getTrigger]
	)

	return {
		event: eventProcess,
		value: progress,
		push,
	}
}
