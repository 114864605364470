import { RegExps } from '@constants/forms'
import { apiUrls } from '@http/api-urls'
import { httpService } from '@http/service'
import { transformAddressToDto, transformStringDateToDto } from '@http/service/shared-transformers'
import { LoanFormDto } from '@shared/components/forms/new-loan/dto'
import { Step2FormModel } from '@shared/components/forms/new-loan/steps/step2/model'
import { consoleDebug, isDebug } from '@shared/pipes/is-debug'

export const sendStep2LoanForm = (dto: LoanFormDto, form: Step2FormModel) => {
	const [series, number] = form.passport.split(' ')
	const passport = {
		series,
		number,
		issueDate: transformStringDateToDto(form.issueDate),
		birthdate: transformStringDateToDto(form.birthdate),
		birthplace: form.birthplace,
		divisionCode: form.divisionCode,
		department: form.department,
		married: form.maritalStatus!.value === 'MARRIED',
		registrationAddress: transformAddressToDto(form.registrationAddress!),
	}

	dto.updateOrCreateParticipant('APPLICANT', {
		passport,
		residenceAddress: transformAddressToDto(
			form.actualAddressEqualWithRegistration ? form.registrationAddress! : form.actualAddress!
		),
		dependents: Number(form.hasChildren),
		incomeAmount: Number(form.incomeAmount.replace(RegExps.notNumber, '')),
	})

	if (isDebug()) {
		return consoleDebug('[Loan form] - prevent send form', dto.build())
	}

	httpService.post(apiUrls.addOrUpdateLead(), dto.build())
}
