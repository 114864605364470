import React from 'react'
import { AnchorsOfForms } from '@constants/forms'
import { Section } from '@shared/components/section'
import { Button } from '@shared/componentsStyled/Button'
import { Link } from '@shared/componentsStyled/Link'
import { changeDocumentHash } from '@shared/pipes/dom'
import scrollToElement from '@shared/pipes/scroll'
import styled from 'styled-components'

import { Input } from '../Input'

import { logInvestPresentation, NAME_MAX_LENGTH, useSectionConsult } from './useSectionConsult'

export const goToConsultLink = () => {
	scrollToElement(`#${AnchorsOfForms.investConsult}`, { time: 0 }, () => {
		changeDocumentHash(AnchorsOfForms.investConsult)
	})
}

export function SectionConsult(): JSX.Element {
	const { isSubmitted, formik, inputDisabled, error } = useSectionConsult()

	return (
		<Section id={AnchorsOfForms.investConsult}>
			<ViewRoot>
				<TextTitle>Нужна консультация?</TextTitle>
				<ViewRow>
					<ViewForm>
						<form onSubmit={formik.handleSubmit}>
							<Label htmlFor='investFondConsultName'>Как к вам обращаться</Label>
							<Input
								id='investFondConsultName'
								name='name'
								type='text'
								placeholder='Имя'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.name}
								hasError={!!formik.errors.name}
								maxLength={NAME_MAX_LENGTH}
								disabled={inputDisabled}
							/>
							<ViewInputInfo>
								{!!formik.errors.name && <TextError>{formik.errors.name}</TextError>}
							</ViewInputInfo>
							<Label htmlFor='investFondConsultPhone'>Номер телефона</Label>
							<Input
								mask='+7 999 999-99-99'
								id='investFondConsultPhone'
								name='phone'
								value={formik.values.phone}
								type='text'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								placeholder='Телефон'
								hasError={!!formik.errors.phone}
								disabled={inputDisabled}
							/>
							<ViewInputInfo last>
								{!!formik.errors.phone && <TextError>{formik.errors.phone}</TextError>}
							</ViewInputInfo>
							<ButtonStyled type='submit' success={isSubmitted} disabled={inputDisabled}>
								{isSubmitted ? 'Отправлено' : 'Получить консультацию'}
							</ButtonStyled>
						</form>
						<ViewFormInfo>{!!error && <TextError>{error}</TextError>}</ViewFormInfo>
					</ViewForm>
					<ViewInfo>
						<TextInfoTitle>Презентация продукта</TextInfoTitle>
						<TextInfo>
							Сохраните информацию о Сбережениях и изучите её позже в формате презентации.
							<br />
							<br />В презентации: основная информация о продукте, актуальные условия, рассказ о
							компании и контакты менеджера.
						</TextInfo>
						<Link
							onClick={logInvestPresentation}
							href='https://storage.yandexcloud.net/creditclub-frontend/static/docs/kpk_deposits/deposits_programms.pdf'
							target='_blank'
							rel='noopener'
						>
							Скачать презентацию
						</Link>
					</ViewInfo>
				</ViewRow>
			</ViewRoot>
		</Section>
	)
}

const ViewRoot = styled.div(({ theme }) => ({
	border: '1px solid rgba(216, 234, 228, 0.25)',
	borderRadius: 40,
	boxShadow: '0px 10px 30px rgba(163, 198, 186, 0.25)',
	marginBottom: 15,
	paddingTop: 20,
	paddingLeft: 20,
	paddingRight: 20,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		marginBottom: 40,
		paddingTop: 40,
		paddingLeft: 60,
		paddingRight: 60,
	},
}))

const TextTitle = styled.h2(({ theme }) => ({
	...theme.typography.header30_36,
	marginBottom: 10,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		...theme.typography.header56_60,
		marginBottom: 20,
	},
}))

const ViewRow = styled.div(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	marginBottom: 20,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		flexDirection: 'row',
		marginBottom: 50,
	},
}))

const ViewForm = styled.div(({ theme }) => ({
	position: 'relative',
	flex: 1,
	marginRight: 0,
	marginBottom: 40,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		marginRight: 80,
		marginBottom: 0,
	},
}))

const ViewInfo = styled.div({
	flex: 1,
})

const Label = styled.label(({ theme }) => ({
	opacity: 0.5,
	display: 'block',
	...theme.typography.text14_18,
	marginBottom: 5,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		...theme.typography.text18_24,
		marginBottom: 10,
	},
}))

interface ViewInputInfoProps {
	last?: boolean | undefined
}
const ViewInputInfo = styled.div<ViewInputInfoProps>(({ theme, last }) => ({
	minHeight: last ? 20 : 10,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		minHeight: last ? 30 : 20,
	},
}))

const ViewFormInfo = styled.div(({ theme }) => ({
	position: 'absolute',
	bottom: -18,
	left: 0,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		bottom: -20,
	},
}))

const TextError = styled.p(({ theme }) => ({
	...theme.typography.text12_16,
	color: theme.colors.error,
	marginBottom: 2,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		...theme.typography.text14_18,
	},
}))

const TextInfoTitle = styled.h3(({ theme }) => ({
	marginBottom: 8,
	...theme.typography.text20_26,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		...theme.typography.text24_32,
	},
}))

const TextInfo = styled.p(({ theme }) => ({
	...theme.typography.text16_22,
	marginBottom: 10,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		...theme.typography.text18_24,
		marginBottom: 20,
	},
}))

interface ButtonStyledProps {
	success?: boolean | undefined
}
const ButtonStyled = styled(Button)<ButtonStyledProps>(({ success }) =>
	!success
		? {}
		: {
				backgroundColor: '#49D4A7',
				'&:hover': {
					backgroundColor: '#49D4A7',
				},
				'&:active': {
					backgroundColor: '#49D4A7',
				},
		  }
)
