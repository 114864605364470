import React, { useCallback, useEffect, useState } from 'react'
import { apiUrls } from '@http/api-urls'
import { httpService } from '@http/service'
import { useLoanForm } from '@shared/components/forms/new-loan'
import { useLoanCalculator } from '@shared/hooks/use-loan-calculator'
import { useDebouncedCallback } from 'use-debounce'

import { CalculateRes } from './model'
import { transformCalculateToDto } from './transform.dto'
import { View } from './view'

export const LoanFormCalculator = () => {
	const loanForm = useLoanForm()
	const calculator = useLoanCalculator({
		periodInitialValue: loanForm.getCalculator()?.period ?? 36,
		loanInitialValue: loanForm.getCalculator()?.loan ?? 1500000,
		percent: loanForm.formOwnProps.calculatorPercent,
		model: loanForm.formOwnProps.financialModel,
	})

	const [payment, setPayment] = useState(0)

	/**
	 * Наличие обьекта calculator в контексте означает что калькулятор ранее заполнялся,
	 * в данном случае не нужно прятать результат
	 */
	const [showResults, setResultsVisibleStatus] = useState(Boolean(loanForm.getCalculator()))

	useEffect(() => {
		loanForm.updateCalculator({
			loan: calculator.sum,
			percent: calculator.percent,
			period: calculator.period,
			payment,
			model: loanForm.formOwnProps.financialModel,
		})
	}, [calculator])

	const fetchCalculateData = async () => {
		try {
			const data: CalculateRes[] = await httpService.post(
				apiUrls.createLoanCalculate(),
				transformCalculateToDto(
					calculator,
					loanForm.formOwnProps.paymentType,
					loanForm.formOwnProps.financialModel
				)
			)

			if (data) {
				setPayment(+data[0]?.paymentAmount.toFixed())
			}
		} catch (err) {
			console.error(err)
		}
	}

	const { callback: getCalculateData } = useDebouncedCallback(fetchCalculateData, 250)

	useEffect(() => {
		getCalculateData()
	}, [calculator.sum, calculator.period])

	const handleShowCalculatorMobileResults = useCallback(() => setResultsVisibleStatus(true), [])

	return View({
		showResults,
		loanSum: calculator.sum,
		period: calculator.period,
		onChangeLoanSum: calculator.changeLoanSum,
		onChangePeriod: calculator.changePeriod,
		pledge: calculator.pledge,
		payment,
		onShowResults: handleShowCalculatorMobileResults,
	})
}
