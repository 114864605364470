import React, { useCallback } from 'react'
import { AnchorsOfForms } from '@constants/forms'
import { Autocomplete } from '@shared/components/autocomplete'
import { Button } from '@shared/components/button'
import { Checkbox } from '@shared/components/checkbox'
import { LoanFormCard } from '@shared/components/forms/new-loan/components/card'
import { LoanFormHint } from '@shared/components/forms/new-loan/components/hint'
import { LoanFormProgressBar } from '@shared/components/forms/new-loan/components/progress-bar'
import { peopleRelationTypes } from '@shared/components/forms/new-loan/shared/option-types/people-relation-types'
import { pledgeTypes } from '@shared/components/forms/new-loan/shared/option-types/pledge-types'
import { TextField } from '@shared/components/text-field'
import { onlyNumInputValueTransformer } from '@shared/components/text-field/value-transformers'
import Safety from '@shared/svg/safety.svg'
import { FormikProvider } from 'formik'

import { IStateProps, Step3FormModel } from './model'

import styles from './style.module.scss'

export const View = ({
	formik,
	fioAutocompleteOptions,
	addressAutocompleteOptions,
	handleChangeFioAutocompleteQuery,
	onNavBack,
	isSubmitting,
	handleChangeAddressAutocompleteQuery,
}: IStateProps) => {
	const handleSubmit = useCallback(
		(e) => {
			e.preventDefault()
			formik.handleSubmit()
		},
		[formik.handleSubmit]
	)

	const values = formik.values as Step3FormModel

	return (
		<FormikProvider value={formik}>
			<form onSubmit={handleSubmit} id={AnchorsOfForms.loanStep3}>
				<LoanFormCard>
					<h2 className={styles.title}>Оставьте заявку на заём</h2>
					<LoanFormProgressBar />
					<div className={styles.hint}>
						<LoanFormHint
							Icon={Safety}
							value='+10% вероятности одобрения заявки за заполнение дополнительной информации'
						/>
					</div>
					<div className={styles.formWrapper}>
						<h4 className='form-section-title'>Объект залога&nbsp;&mdash; квартира</h4>
						<h5 className={styles.h5}>
							Не&nbsp;рассматриваем другие виды недвижимости: дома, комнаты, доли
							и&nbsp;коммерческие помещения.
						</h5>
						<div role='input-wrap'>
							<Checkbox
								disabled={isSubmitting}
								label='Адрес совпадает с местом регистрации'
								name='facilityAddressEqualWithRegistration'
							/>
						</div>
						{!values.facilityAddressEqualWithRegistration && (
							<div role='input-wrap'>
								<Autocomplete
									label='Адрес'
									blurOnSelect
									freeSolo
									disabled={isSubmitting}
									autoSelect
									name='facilityAddress'
									inputRequired
									options={addressAutocompleteOptions}
									onInputChange={handleChangeAddressAutocompleteQuery}
									helperText='Область, город / населённый пункт, улица, дом, квартира'
								/>
							</div>
						)}
						{/* <div role="input-wrap">
							<Autocomplete
								label="Тип обьекта"
								blurOnSelect
								disabled={isSubmitting}
								name="facilityType"
								inputRequired
								getOptionLabel={o => o.label}
								options={pledgeTypes}
							/>
						</div> */}
						<div role='input-wrap'>
							<TextField
								transformValue={onlyNumInputValueTransformer}
								required
								disabled={isSubmitting}
								name='facilityArea'
								label='Площадь объекта залога, м2'
							/>
						</div>
						<h4 className='form-section-title'>Информация о собственнике </h4>
						<div role='inputs-wrap-2'>
							<div role='input-wrap'>
								<Checkbox
									disabled={isSubmitting}
									label='Являюсь собственником'
									name='applicantIsOwner'
								/>
							</div>
							<div role='input-wrap'>
								<Checkbox
									disabled={isSubmitting}
									label='Единственный собственник'
									name='applicantIsSoleOwner'
								/>
							</div>
						</div>
						{!(values.applicantIsSoleOwner && values.applicantIsOwner) && (
							<>
								<div role='input-wrap'>
									<Autocomplete
										label='Фамилия, имя, отчество собственника недвижимости'
										freeSolo
										disabled={isSubmitting}
										autoSelect
										blurOnSelect
										name='secondOwnerFio'
										inputRequired
										options={fioAutocompleteOptions}
										onInputChange={handleChangeFioAutocompleteQuery}
									/>
								</div>
								<div role='input-wrap'>
									<Autocomplete
										disabled={isSubmitting}
										label='Принадлеждность к заёмщику'
										blurOnSelect
										name='secondOwnerRelationWithApplicant'
										inputRequired
										getOptionLabel={(o) => o.label}
										options={peopleRelationTypes}
									/>
								</div>
							</>
						)}
						<div className={styles.controls}>
							<Button disabled={isSubmitting} type='submit'>
								{isSubmitting ? 'Отправка...' : 'Отправить заявку'}
							</Button>
							<Button
								disabled={isSubmitting}
								variant='outlined'
								onClick={onNavBack}
								className={styles.backBtn}
							>
								Назад
							</Button>
						</div>
					</div>
				</LoanFormCard>
			</form>
		</FormikProvider>
	)
}
