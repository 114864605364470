import { apiUrls } from '@http/api-urls'
import { httpService } from '@http/service'
import { transformAddressToDto, transformFioToDto } from '@http/service/shared-transformers'
import { LoanFormDto } from '@shared/components/forms/new-loan/dto'
import { Step2FormModel } from '@shared/components/forms/new-loan/steps/step2/model'
import { Step3FormModel } from '@shared/components/forms/new-loan/steps/step3/model'
import { consoleDebug, isDebug } from '@shared/pipes/is-debug'

export const sendStep3LoanForm = (
	dto: LoanFormDto,
	step2form: Step2FormModel,
	form: Step3FormModel
): Promise<void> => {
	dto
		.updateOrCreateFacility({
			area: Number(form.facilityArea),
			// type: form.facilityType.value,
			type: 'FLAT',
			address: transformAddressToDto(
				form.facilityAddressEqualWithRegistration
					? step2form.registrationAddress!
					: form.facilityAddress!
			).mergedAddress,
		})
		.updateOrCreateParticipant(
			'OWNER',
			form.applicantIsSoleOwner && form.applicantIsOwner
				? null
				: transformFioToDto(form.secondOwnerFio!)
		)

	if (isDebug()) {
		consoleDebug('[Loan form] - prevent send form', dto.build())
		return Promise.resolve()
	}

	return httpService.post(apiUrls.addOrUpdateLead(), dto.build())
}
