import { RegExps } from '@constants/forms'
import { Step1FormModel } from '@shared/components/forms/new-loan/steps/step1/form/model'
import { customizeYup } from '@shared/components/forms/shared/validation/customize-yup'
import { mixed, object, string } from 'yup'

customizeYup()

export const step1ValidationSchema = object<Step1FormModel>({
	phone: string()
		.matches(RegExps.phone, {
			message: 'Указан не верный формат номера',
		})
		.required(),
	email: string().email().required(),
	fio: mixed().required() as any,
	purpose: mixed().required() as any,
})
