import { RegExps } from '@constants/forms'
import { customizeYup } from '@shared/components/forms/shared/validation/customize-yup'
import { object, string } from 'yup'

import { SimplePersonFormModel } from './model'

customizeYup()

export const createValidationSchema = (email: boolean) => {
	const schema = object<SimplePersonFormModel>({
		name: string().required(),
		phone: string()
			.matches(RegExps.phone, {
				message: 'Указан не верный формат номера',
			})
			.required(),
	})

	if (email) {
		return schema.shape({ email: string().email().required() })
	}

	return schema
}
