/**
 * Скролит наверх до формы на которую вернулись с предыдущего шага
 */
import { useEffect } from 'react'
import { AnchorsOfForms } from '@constants/forms'
import { useLoanForm } from '@shared/components/forms/new-loan'
import scrollToElement from '@shared/pipes/scroll'

export const useScrollTopTouchedForm = (
	anchor: AnchorsOfForms,
	scrollIntoViewSettings?: __ScrollIntoView.Settings,
	noScrollIfNoTouched?: boolean
) => {
	const loanForm = useLoanForm()
	useEffect(() => {
		if (!noScrollIfNoTouched || loanForm.getFormData(loanForm.currentStep)) {
			scrollToElement(`#${anchor}`, scrollIntoViewSettings)
		}
	}, [])
}
