import React from 'react'
import { Image } from '@shared/components/image'
import { Section } from '@shared/components/section'
import { servicesInvestors } from '@shared/components/sections/services-investors/index'

import styles from './style.module.scss'

const Wrapper = ({ href, ...rest }: any) => {
	let props = { ...rest }

	if (href) {
		props = {
			...props,
			href,
			target: '_blank',
			rel: 'noopener noreferrer',
		}
	}

	return React.createElement(href ? 'a' : 'div', props)
}

export const View = () => (
	<Section className={styles.wrapper}>
		{servicesInvestors.map(({ image, title, href }, index) => (
			<Wrapper href={href} key={index} className={styles.service}>
				<div className={styles.logo}>
					<Image lazy name={image} />
				</div>
				<h2 className={styles.serviceTitle}>{title}</h2>
			</Wrapper>
		))}
	</Section>
)
