import { ExternalPaths } from '@shared/menu-items/paths'

import { View } from './view'

interface ServicesInvestors {
	image: string
	title: string
	href?: string
}

export const servicesInvestors: ServicesInvestors[] = [
	{
		image: 'logo-operators.png',
		title: 'Состоим в Ассоциации операторов инвестиционных платформ',
	},
	{
		image: 'logo-rosreestr.png',
		title: 'Используем сервис удалённого взаимодействия Росреестра',
	},
	{
		image: 'sk-logo.png',
		title: 'Участник проекта инновационного центра «Сколково»',
		href: ExternalPaths.skolkovo,
	},
]

export const ServicesInvestors = View
