import { useState } from 'react'
import { apiUrls } from '@http/api-urls'
import { httpService } from '@http/service'
import { yaCounter } from '@shared/pipes/ya-counter'
import { useFormik } from 'formik'
import { object, string } from 'yup'

export const NAME_MAX_LENGTH = 500
export const logInvestPresentation = (): void =>
	yaCounter.utils.reachGoal('invest_download_presentation')
const logInvestLead = (): void => yaCounter.utils.reachGoal('invest_lead')

interface FormProps {
	name: string
	phone: string
}

const validationSchema = object().shape({
	name: string().trim().required('Это обязательное поле').max(NAME_MAX_LENGTH),
	phone: string()
		.matches(/\+?[7]\s(\d{3})\s(\d{3})-(\d{2})-(\d{2})/, {
			message: 'Указан неверный формат номера',
		})
		.required('Это обязательное поле'),
})

const apiSubmit = (formProps: FormProps) => {
	const phone = formProps.phone.replace(/[^0-9]/g, '').slice(-10)
	const payload = {
		product: 'SAVING',
		name: formProps.name.trim(),
		phone,
	}
	return httpService.post(apiUrls.investmentLeads(), payload)
}

export const useSectionConsult = () => {
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [error, setError] = useState<string | null>(null)
	const formik = useFormik<FormProps>({
		initialValues: {
			name: '',
			phone: '',
		},
		validationSchema,
		validateOnChange: false,
		onSubmit: async (values) => {
			logInvestLead()
			setIsSubmitted(false)
			setIsLoading(true)
			setError(null)
			try {
				await apiSubmit(values)
				setIsSubmitted(true)
			} catch (error) {
				setError('Ошибка, Попробуйте позже')
				// display error message
				throw error
			} finally {
				setIsLoading(false)
			}
		},
	})

	return {
		isSubmitted,
		error,
		// isLoading,
		formik,
		inputDisabled: isLoading || isSubmitted,
	}
}
