import { AnchorsOfForms, AnchorsOfFormsWrappers } from '@constants/forms'
import { apiUrls } from '@http/api-urls'
import { httpService } from '@http/service'
import { transformEstateAgentsFormToDto } from '@shared/components/forms/estate-agents/transform.dto'
import { SimplePersonForm } from '@shared/components/forms/shared/simple-person-form'
import { changeDocumentHash } from '@shared/pipes/dom'
import scrollToElement from '@shared/pipes/scroll'

interface Props {
	btnText?: string
	placeholders?: string[]
}

const defaultBtnText = 'Стать партнёром'

export const EstateAgentsForm = ({
	btnText = defaultBtnText,
	placeholders = ['Фамилия, имя, отчество', 'Номер телефона', 'Электронная почта'],
}: Props) =>
	SimplePersonForm({
		placeholders,
		formId: AnchorsOfForms.partner,
		onSubmit: (form) =>
			httpService.post(apiUrls.sendSlackMessageToManager(), transformEstateAgentsFormToDto(form)),
		buttonText: (isSubmitted) =>
			isSubmitted ? 'Спасибо за заявку. В течение 30 минут с\u00A0вами свяжется менеджер' : btnText,
	})

export const scrollToEstateAgentsForm = () => {
	scrollToElement(`#${AnchorsOfFormsWrappers.partner}`, {}, () => {
		changeDocumentHash(AnchorsOfFormsWrappers.partner)
	})
}
