import React from 'react'
import { Paths } from '@shared/menu-items/paths'

import { useRegions } from '../../../regions/provider'

import styles from './style.module.scss'

const links = [
	{
		href: Paths.personalNaZhile,
		label: 'Кредит на жильё',
	},
	{
		href: Paths.personalNaObrazovanie,
		label: 'Кредит на образование',
	},
	{
		href: Paths.personalNaRemont,
		label: 'Кредит на ремонт',
	},
	{
		href: Paths.personalNaPokupkuDoma,
		label: 'Кредит на дом',
	},
	{
		href: Paths.personalNaRefinansirovanie,
		label: 'Кредит на рефинансирование',
	},
	{
		href: Paths.personalNaStroitelstvo,
		label: 'Кредит на строительство',
	},
]

export const PersonalFooterExtra = () => {
	const regions = useRegions()
	const buildHrefRegion = (href: string) => {
		return `/${regions.currentCity.key}${href}`
	}

	return (
		<>
			<div className={styles.links}>
				<div>
					{links.slice(0, 3).map((link) => (
						<a
							href={buildHrefRegion(link.href)}
							title={link.label}
							key={link.label}
							target='_blank'
							rel='noopener noreferrer'
							className={styles.link}
						>
							{link.label}
						</a>
					))}
				</div>
				<div className='mt-m-mob'>
					{links.slice(3).map((link) => (
						<a
							href={buildHrefRegion(link.href)}
							title={link.label}
							key={link.label}
							target='_blank'
							rel='noopener noreferrer'
							className={styles.link}
						>
							{link.label}
						</a>
					))}
				</div>
			</div>
			<noindex>
				<div className={styles.content}>
					<div>
						* Услуги физическим лицам оказывает Кредитный потребительский кооператив «Фонд
						Ипотечного Кредитования» (Свердловская область, г. Екатеринбург, ОГРН 1196658040677 ИНН
						6678102434, официальный сайт https://loanfund.ru/) в рамках Федерального закона № 190-ФЗ
						от 18.07.2009 года путем заключения договора займа на следующих условиях.
						<br />
						<br />
						Сумма займа от 200 000 до 15 000 000 рублей. Процентная ставка за пользование займом —
						от 13,9% до 16,9% годовых в зависимости от выбранной программы выдачи займов, процентная
						ставка может изменяться в указанном диапазоне в случае наличия задолженности по внесению
						платежей. Минимальный срок займа — 12 месяцев. Максимальный срок займа — 120 месяцев.
						Платежи по оплате основного долга и процентов по займу подразделяются на 3 вида
						(аннуитетный, процентный, смешанный), и определяются индивидуальными условиями договора
						займа. Помимо указанных платежей заемщик платит членские взносы, состоящие из двух
						частей – Постоянного членского взноса начисленного при выдаче займа и Переменного
						членского взноса (предусмотрен не во всех программах), начисляемого в зависимости от
						остатка задолженности по Займу (Основному долгу) и подлежащему ежемесячному внесению в
						день платежа по займу.
						<br />
						<br />
						Размер постоянного членского взноса определяется по формуле: (срок займа в месяцах) *
						(сумма займа) * 0,005-0,5% (в зависимости от выбранной программы выдачи займов).
					</div>
					<div>
						Размер Переменного членского взноса (в случае его наличия) составляет 8,9% - 12% (в
						зависимости от выбранной программы выдачи займов) годовых на сумму остатка основного
						долга по договору займа.
						<br />
						<br />
						Полная стоимость займа составляет от 13,9 до 34% годовых в зависимости от индивидуальных
						условий займа. Размер неустойки за ненадлежащее исполнение условий договора 0,02% в день
						от суммы просроченной задолженности.
						<br />
						<br />
						Обеспечение исполнения обязательств - залог жилого недвижимого имущества, возможно
						поручительство физического лица.
						<br />
						<br />
						Кредитный потребительский кооператив оказывает услуги исключительно членам кредитного
						кооператива (пайщикам). При вступлении в кооператив вносится обязательный паевой взнос в
						размере 50 рублей.
						<br />
						<br />
						Член кредитного кооператива (пайщика) обязаны нести субсидиарную ответственность по
						обязательствам кооператива в пределах невнесенной части дополнительного взноса каждого
						из членов кредитного кооператива (пайщиков).
						<br />
						<br />
						Под кредитом в вышеизложенных материалах понимаются экономические отношения, которые
						заключаются в получении заёмщиком от кредитора денежных средств на условиях возврата и
						платности, в том числе по договору займа. Не является публичной офертой.
					</div>
				</div>
			</noindex>
		</>
	)
}
