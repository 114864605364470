import { RegExps } from '@constants/forms'
import { Step2FormModel } from '@shared/components/forms/new-loan/steps/step2/model'
import { customizeYup } from '@shared/components/forms/shared/validation/customize-yup'
import { dateStringSchema } from '@shared/components/forms/shared/validation/shared.schema'
import { mixed, object, string } from 'yup'

customizeYup()

const defaultErrMsg = 'Некоректное значение'

export const step2ExtraApplicantInfoValidationSchema = object<Step2FormModel>().shape({
	passport: string()
		.matches(RegExps.number, {
			message: defaultErrMsg,
		})
		.required(),
	birthdate: dateStringSchema,
	birthplace: string().required(),
	divisionCode: string()
		.matches(RegExps.number, {
			message: defaultErrMsg,
		})
		.required(),
	issueDate: dateStringSchema,
	department: string().required(),
})

export const step2ActualAddressValidationSchema = object<Step2FormModel>().shape({
	actualAddress: mixed<any>().when('actualAddressEqualWithRegistration', {
		is: true,
		then: null,
		otherwise: mixed().required(),
	}),
})

export const step2ValidationSchema = object<Step2FormModel>()
	.shape({
		registrationAddress: mixed().required() as any,
		actualAddressEqualWithRegistration: null as any,
		incomeAmount: string().required() as any,
		maritalStatus: object().required().nullable(true) as any,
		hasChildren: null as any,
	})
	.concat(step2ExtraApplicantInfoValidationSchema)
	.concat(step2ActualAddressValidationSchema)
