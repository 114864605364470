import { Step3FormModel } from '@shared/components/forms/new-loan/steps/step3/model'
import { mixed, object, string } from 'yup'

export const step3PledgeValidationSchema = object<Step3FormModel>().shape({
	facilityAddressEqualWithRegistration: null as any,
	facilityAddress: mixed<any>().when('facilityAddressEqualWithRegistration', {
		is: true,
		then: null,
		otherwise: mixed().required(),
	}),
	// facilityType: mixed().required() as any,
	facilityArea: string().required(),
})

export const step3OwnerValidationSchema = object<Step3FormModel>().shape({
	secondOwnerFio: mixed<any>().when('applicantIsSoleOwner', {
		is: true,
		then: null,
		otherwise: mixed<any>().when('applicantIsOwner', {
			is: true,
			then: null,
			otherwise: mixed().required(),
		}),
	}),
	applicantIsSoleOwner: null as any,
	secondOwnerRelationWithApplicant: mixed<any>().when('applicantIsSoleOwner', {
		is: true,
		then: null,
		otherwise: mixed<any>().when('applicantIsOwner', {
			is: true,
			then: null,
			otherwise: object().required().nullable(),
		}),
	}),
})

export const step3ValidationSchema = object<Step3FormModel>()
	.concat(step3PledgeValidationSchema as any)
	.concat(step3OwnerValidationSchema as any)
