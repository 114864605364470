import React, { useCallback } from 'react'
import { Sizes } from '@constants/sizes'
import { Button } from '@shared/components/button'
import { LoanFormProgressBar } from '@shared/components/forms/new-loan/components/progress-bar'
import { InputRange } from '@shared/components/input-range'
import { useWindowSize } from '@shared/hooks/use-window-size'
import { breaksHundreds, formatCalcSum } from '@shared/utils/helpers'

import { IStateProps } from './model'

import styles from './style.module.scss'

const inputRangeClassNames = {
	resultClassName: styles.inputRangeResult,
	activeTrackClassName: styles.activeTrack,
	trackClassName: styles.track,
}

export const View = ({
	onChangeLoanSum,
	onChangePeriod,
	loanSum,
	period,
	showResults,
	onShowResults,
	payment,
	pledge,
}: IStateProps) => {
	const { width } = useWindowSize()
	const formatResult = useCallback((value) => `от ${breaksHundreds(value.toFixed())} ₽`, [])
	const formatLabel = (value) => `${formatCalcSum(value)} ₽`

	return (
		<>
			<div className={styles.wrapper}>
				<h2 className={styles.title}>Оставьте заявку на заём</h2>
				<LoanFormProgressBar />
				<div className={styles.calculatorInputsWrap}>
					<div className={styles.calculatorInput}>
						<h5 className={styles.calculatorInputLabel}>Сумма займа</h5>
						<InputRange
							value={loanSum}
							onChange={onChangeLoanSum}
							formatLabel={formatLabel}
							min={200000}
							max={15000000}
							{...inputRangeClassNames}
						/>
					</div>
					<div className={styles.calculatorInput}>
						<h5 className={styles.calculatorInputLabel}>Срок займа, мес</h5>
						<InputRange
							value={period}
							onChange={onChangePeriod}
							min={1}
							max={60}
							step={1}
							{...inputRangeClassNames}
						/>
					</div>
				</div>
			</div>
			<div className={styles.resultsWrapper}>
				{width! < Sizes.tablet && !showResults && (
					<Button className={styles.mobileButton} onClick={onShowResults}>
						Расчитать платёж
					</Button>
				)}
				{(width! >= Sizes.tablet || showResults) && (
					<div className={styles.results}>
						<div className={styles.resultsItem}>
							<span className={styles.resultsLabel}>Платёж в месяц *</span>
							<h4>{formatResult(payment)}</h4>
						</div>
						<div className={styles.resultsItem}>
							<span className={styles.resultsLabel}>Стоимость объектов залога</span>
							<h4>{formatResult(pledge)}</h4>
						</div>
					</div>
				)}
			</div>
		</>
	)
}
