import { AutocompleteOptionForSelect } from '@shared/components/autocomplete/model'

export const peopleRelationTypes: AutocompleteOptionForSelect<string>[] = [
	{
		label: 'Супруг/супруга',
		value: 'SPOUSE',
	},
	{
		label: 'Отец/мать',
		value: 'PARENT',
	},
	{
		label: 'Брат/сестра',
		value: 'BROTHER_OF_SISTER',
	},
	{
		label: 'Сын/дочь',
		value: 'CHILD',
	},
	{
		label: 'Другой родственник',
		value: 'OTHER_RELATION',
	},
	{
		label: 'Иное',
		value: 'OTHER',
	},
]
