import { DadataFio, DaDataSuggestion } from '@http/dadata/models'
import { transformFioToDto } from '@http/service/shared-transformers'

export const loanFormParticipantFactory = (
	fio: string | DaDataSuggestion<DadataFio>,
	extra?: { [prop: string]: any }
) => {
	return {
		...transformFioToDto(fio),
		...extra,
	}
}
