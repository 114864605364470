import { useCallback } from 'react'
import { apiUrls } from '@http/api-urls'
import { httpService } from '@http/service'
import { useInvestorPopupForm } from '@providers/investor-popup-form'
import { transformInvestorFormToDto } from '@shared/components/forms/investor/transform.dto'
import { SimplePersonFormModel } from '@shared/components/forms/shared/simple-person-form/model'

import { Props } from './model'
import { View } from './view'

export const InvestorFormComponent = (props: Props) => {
	const investorPopupForm = useInvestorPopupForm()
	const handleSubmitForm = useCallback((form: SimplePersonFormModel) => {
		return httpService.post(apiUrls.addInvestor(), transformInvestorFormToDto(form))
	}, [])

	return View({
		...props,
		onModalClose: investorPopupForm.close,
		showModal: investorPopupForm.isVisible,
		onSubmitForm: handleSubmitForm,
		onStartSubmitForm: props.onStartSubmitForm,
	})
}
