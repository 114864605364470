import { RegExps } from '@constants/forms'
import { InputProps } from '@shared/components/text-field/model'
import { breaksHundreds } from '@shared/utils/helpers'

export const onlyNumInputValueTransformer = (value: string) => {
	if (value.length && !RegExps.onlyNumbers.test(value)) {
		return value.replace(RegExps.notNumber, '')
	}

	return value
}

export const breaksHundredsInputValueTransformer = (value: string) => {
	if (value) {
		return breaksHundreds(value)
	}

	return value
}

export const applyInputTransformers = (
	transformers: InputProps['transformValue'][]
): InputProps['transformValue'] => {
	return (value: string): string => {
		return transformers.reduce((acc, transformer) => transformer!(acc), value)
	}
}
