import React from 'react'
import { AnchorsOfForms } from '@constants/forms'
import { LoanFormCard } from '@shared/components/forms/new-loan/components/card'
import { Image } from '@shared/components/image'
import { servicesInvestors } from '@shared/components/sections/services-investors'
import { ExternalPaths, Paths } from '@shared/menu-items/paths'
import { getFullLinkByPath } from '@shared/pipes/dom'
import LoanFormSuccessPattern from '@shared/svg/patterns/loan-form-success.svg'

import styles from './style.module.scss'

const links = [
	{
		label: 'Компания Credit.Club',
		href: getFullLinkByPath(Paths.about),
	},
	{
		label: 'Credit.Club в лицах',
		href: '#',
	},
	{
		label: 'Журнал «Процент» про кредиты',
		href: ExternalPaths.companyJournal,
	},
	{
		label: 'Ведём канал на Youtube',
		href: ExternalPaths.companyYoutubeChannel,
	},
]

const ServiceInvestorWrapper = ({ href, ...rest }: any) => {
	let props = { ...rest }

	if (href) {
		props = {
			...props,
			href,
			target: '_blank',
			rel: 'noopener noreferrer',
		}
	}

	return React.createElement(href ? 'a' : 'div', props)
}

export const View = () => {
	return (
		<LoanFormCard id={AnchorsOfForms.loanStepSuccess} className={styles.root}>
			<div className={styles.header}>
				<h2 className={styles.title}>Спасибо!</h2>
				<h4 className={styles.subtitle}>
					Заявка уже у нас. Мы свяжемся <br />с вами в течение 30 минут и сообщим предварительное
					решение.
				</h4>
				<div className={styles.headerPattern}>
					<LoanFormSuccessPattern />
				</div>
			</div>
			<div className={styles.contentWrap}>
				<h3>Пока можете познакомиться с компанией</h3>
				<div className={styles.contentLinks}>
					{links.map(({ label, href }) => (
						<a className={styles.link} href={href} key={label}>
							{label}
						</a>
					))}
				</div>
			</div>
			<div className={styles.services}>
				{servicesInvestors.map(({ image, title, href }, index) => (
					<ServiceInvestorWrapper href={href} key={index} className={styles.service}>
						<div className={styles.logo}>
							<Image lazy name={image} />
						</div>
						<span className={styles.serviceTitle}>{title}</span>
					</ServiceInvestorWrapper>
				))}
			</div>
		</LoanFormCard>
	)
}
