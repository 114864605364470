import { Step3FormModel } from '@shared/components/forms/new-loan/steps/step3/model'

export const step3InitialValues: Step3FormModel = {
	facilityAddress: null,
	facilityAddressEqualWithRegistration: false,
	// facilityType: null,
	facilityArea: '',
	applicantIsOwner: false,
	applicantIsSoleOwner: false,
	secondOwnerFio: null,
	secondOwnerRelationWithApplicant: null,
}
