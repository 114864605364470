import React from 'react'
import { AnchorsOfForms } from '@constants/forms'

import styles from './style.module.scss'

export const View = ({ children }) => {
	return (
		<div id={AnchorsOfForms.loanLayout} className={styles.root}>
			{children}
		</div>
	)
}
