import { useCallback, useEffect, useState } from 'react'
import {
	DEFAULT_LOAN_SUM,
	DEFAULT_PERCENT,
	DEFAULT_PERIOD,
	PROPERTY_VALUE_RATIO,
} from '@constants/loan'
import { FinancialModel } from '@shared/components/forms/new-loan/model'

export interface LoanCalculatorParams {
	loanInitialValue?: number
	periodInitialValue?: number
	percent?: number
	model: FinancialModel
}

export interface UseLoanCalculatorResult {
	sum: number
	period: number
	pledge: number
	percent: number
	changeLoanSum(newValue: string | number): void
	changePeriod(newValue: string | number): void
}

export const useLoanCalculator = (params: LoanCalculatorParams): UseLoanCalculatorResult => {
	const [sum, setSum] = useState(params.loanInitialValue ?? DEFAULT_LOAN_SUM)
	const [period, setPeriod] = useState(params.periodInitialValue ?? DEFAULT_PERIOD)
	const [pledge, setPledge] = useState(0)

	useEffect(() => {
		setPledge(sum * PROPERTY_VALUE_RATIO)
	}, [sum])

	const handleChangeLoanSum = useCallback((value) => setSum(+value), [])
	const handleChangePeriod = useCallback((value) => setPeriod(+value), [])

	return {
		period,
		sum,
		pledge,
		changePeriod: handleChangePeriod,
		changeLoanSum: handleChangeLoanSum,
		percent: params.percent ?? DEFAULT_PERCENT,
	}
}
