import { useCallback, useEffect } from 'react'
import { AnchorsOfForms } from '@constants/forms'
import { DadataAddress, DaDataSuggestion } from '@http/dadata/models'
import { useLoanForm } from '@shared/components/forms/new-loan'
import { LoanFormProcessEvents } from '@shared/components/forms/new-loan/progress/progress-setup'
import { useScrollTopTouchedForm } from '@shared/components/forms/new-loan/shared/hooks/use-scroll-top-touched-form'
import { sendStep2LoanForm } from '@shared/components/forms/new-loan/steps/step2/api'
import { step2InitialValues } from '@shared/components/forms/new-loan/steps/step2/initial-values'
import { Step2FormModel } from '@shared/components/forms/new-loan/steps/step2/model'
import {
	step2ActualAddressValidationSchema,
	step2ExtraApplicantInfoValidationSchema,
	step2ValidationSchema,
} from '@shared/components/forms/new-loan/steps/step2/validation.shema'
import { useAsyncAutocomplete } from '@shared/hooks/use-async-autocomplete'
import { DadataAddressRequestRunner } from '@shared/hooks/use-async-autocomplete/request-runners'
import { useFormik } from 'formik'

import { View } from './view'

export const Step2 = () => {
	const loanForm = useLoanForm()
	useScrollTopTouchedForm(AnchorsOfForms.loanStep2, { align: { topOffset: 90 } })

	const formik = useFormik<Step2FormModel>({
		validationSchema: step2ValidationSchema,
		initialValues: loanForm.getFormData<Step2FormModel>(loanForm.currentStep) || step2InitialValues,

		async onSubmit(values) {
			sendStep2LoanForm(loanForm.dto, values)
			loanForm.nextStep(values)
		},
	})

	useEffect(() => {
		loanForm.progress.event(
			LoanFormProcessEvents.fillRegistrationAddress,
			Boolean(formik.values.registrationAddress)
		)

		loanForm.progress.event(
			LoanFormProcessEvents.fillExtraApplicantInfo,
			step2ExtraApplicantInfoValidationSchema.isValid(formik.values)
		)

		loanForm.progress.event(
			LoanFormProcessEvents.fillActualAddress,
			step2ActualAddressValidationSchema
				.isValid(formik.values)
				.then((r) => r && Boolean(formik.values.registrationAddress))
		)
	}, [formik.values])

	const [autoCompleteResponse, , handleSetQuery] = useAsyncAutocomplete<
		DaDataSuggestion<DadataAddress>
	>(new DadataAddressRequestRunner())

	const handleBack = useCallback(() => {
		loanForm.prevStep(formik.values)
	}, [formik.values])

	return View({
		formik,
		onNavBack: handleBack,
		addressAutocompleteOptions: autoCompleteResponse,
		handleChangeAddressAutocompleteQuery: (_, query) => handleSetQuery(query),
	})
}
