import React, { useCallback } from 'react'
import { AnchorsOfForms, Masks } from '@constants/forms'
import { Autocomplete } from '@shared/components/autocomplete'
import { Button } from '@shared/components/button'
import { Checkbox } from '@shared/components/checkbox'
import { LoanFormCard } from '@shared/components/forms/new-loan/components/card'
import { LoanFormHint } from '@shared/components/forms/new-loan/components/hint'
import { LoanFormProgressBar } from '@shared/components/forms/new-loan/components/progress-bar'
import { typesOfMaritalStatus } from '@shared/components/forms/new-loan/shared/option-types/types-of-marital-status'
import { TextField } from '@shared/components/text-field'
import {
	applyInputTransformers,
	breaksHundredsInputValueTransformer,
	onlyNumInputValueTransformer,
} from '@shared/components/text-field/value-transformers'
import Safety from '@shared/svg/safety.svg'
import { FormikProvider } from 'formik'

import { IStateProps } from './model'
import { Step2FormModel } from './model'

import styles from './style.module.scss'

export const View = ({
	onNavBack,
	formik,
	addressAutocompleteOptions,
	handleChangeAddressAutocompleteQuery,
}: IStateProps) => {
	const handleSubmit = useCallback(
		(e) => {
			e.preventDefault()
			formik.handleSubmit()
		},
		[formik.handleSubmit]
	)

	const values = formik.values as Step2FormModel

	return (
		<FormikProvider value={formik}>
			<form onSubmit={handleSubmit} id={AnchorsOfForms.loanStep2}>
				<LoanFormCard>
					<h2 className={styles.title}>Оставьте заявку на заём</h2>
					<LoanFormProgressBar />
					<div className={styles.hint}>
						<LoanFormHint
							Icon={Safety}
							value='+10% вероятности одобрения заявки за заполнение дополнительной информации'
						/>
					</div>
					<div className={styles.formWrapper}>
						<h4 className='form-section-title'>Дополнительная информация</h4>
						<div role='inputs-wrap-2'>
							<div role='input-wrap'>
								<TextField
									required
									name='passport'
									label='Серия и номер паспорта'
									mask={Masks.passport}
									maskChar='_'
								/>
							</div>
							<div role='input-wrap'>
								<TextField
									required
									name='birthdate'
									label='Дата рождения'
									mask={Masks.stringDate}
									maskChar='_'
								/>
							</div>
						</div>
						<div role='input-wrap'>
							<TextField required name='birthplace' label='Место рождения' />
						</div>
						<div role='inputs-wrap-2'>
							<div role='input-wrap'>
								<TextField
									required
									name='issueDate'
									label='Дата выдачи'
									mask={Masks.stringDate}
									maskChar='_'
								/>
							</div>
							<div role='input-wrap'>
								<TextField
									required
									name='divisionCode'
									label='Код подразделения'
									mask={Masks.passportDivisionCode}
									maskChar='_'
								/>
							</div>
						</div>
						<div role='input-wrap'>
							<TextField required name='department' label='Кем выдан' />
						</div>
						<h4 className='form-section-title'>Адрес постоянной регистрации</h4>
						<div role='input-wrap'>
							<Autocomplete
								label='Адрес'
								autoSelect
								freeSolo
								blurOnSelect
								name='registrationAddress'
								inputRequired
								options={addressAutocompleteOptions}
								onInputChange={handleChangeAddressAutocompleteQuery}
								helperText='Область, город / населённый пункт, улица, дом, квартира'
							/>
						</div>
						<h4 className='form-section-title'>Адрес фактического проживания</h4>
						<div role='input-wrap'>
							<Checkbox
								label='Совпадает с адресом регистрации'
								name='actualAddressEqualWithRegistration'
							/>
						</div>
						{!values.actualAddressEqualWithRegistration && (
							<div role='input-wrap'>
								<Autocomplete
									label='Адрес'
									blurOnSelect
									freeSolo
									autoSelect
									name='actualAddress'
									inputRequired
									options={addressAutocompleteOptions}
									onInputChange={handleChangeAddressAutocompleteQuery}
									helperText='Область, город / населённый пункт, улица, дом, квартира'
								/>
							</div>
						)}
						<h4 className='form-section-title'>Семейное положение</h4>
						<div role='inputs-wrap-2'>
							<div role='input-wrap'>
								<TextField
									transformValue={applyInputTransformers([
										onlyNumInputValueTransformer,
										breaksHundredsInputValueTransformer,
									])}
									required
									name='incomeAmount'
									label='Персональный доход в месяц'
								/>
							</div>
							<div role='input-wrap'>
								<Autocomplete
									label='Семейное положение'
									blurOnSelect
									name='maritalStatus'
									inputRequired
									getOptionLabel={(o) => o.label}
									options={typesOfMaritalStatus}
								/>
							</div>
						</div>
						<div role='input-wrap'>
							<Checkbox label='Есть несовешеннолетние дети ' name='hasChildren' />
						</div>
						<div className={styles.hint}>
							<LoanFormHint value='+10% вероятности одобрения за заполнение следующего шага' />
						</div>
						<div className={styles.controls}>
							<Button type='submit'>Далее</Button>
							<Button variant='outlined' onClick={onNavBack} className={styles.backBtn}>
								Назад
							</Button>
						</div>
					</div>
				</LoanFormCard>
			</form>
		</FormikProvider>
	)
}
