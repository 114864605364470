import { ProgressTrigger } from '@shared/components/forms/new-loan/progress/progress'

export enum LoanFormProcessEvents {
	init = 'init',
	fillFio = 'fillFio',
	fillExtraApplicantInfo = 'fillExtraApplicantInfo',
	fillRegistrationAddress = 'fillRegistrationAddress',
	fillActualAddress = 'fillActualAddress',
	fillPledge = 'fillPledge',
	fillOwner = 'fillOwner',
}

export const loanFormProgressEvents: ProgressTrigger<LoanFormProcessEvents>[] = [
	{
		type: LoanFormProcessEvents.init,
		value: 7,
	},
	{
		type: LoanFormProcessEvents.fillFio,
		value: 16,
	},
	{
		type: LoanFormProcessEvents.fillExtraApplicantInfo,
		value: 10,
	},
	{
		type: LoanFormProcessEvents.fillRegistrationAddress,
		value: 20,
	},
	{
		type: LoanFormProcessEvents.fillActualAddress,
		value: 12,
	},
	{
		type: LoanFormProcessEvents.fillPledge,
		value: 6,
	},
	{
		type: LoanFormProcessEvents.fillOwner,
		value: 7,
	},
]
