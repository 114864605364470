import { Step2FormModel } from '@shared/components/forms/new-loan/steps/step2/model'

export const step2InitialValues: Step2FormModel = {
	passport: '',
	birthdate: '',
	birthplace: '',
	divisionCode: '',
	issueDate: '',
	department: '',
	registrationAddress: null,
	actualAddress: null,
	actualAddressEqualWithRegistration: true,
	incomeAmount: '',
	maritalStatus: null,
	hasChildren: false,
}
