import { getMarketingData } from '@shared/pipes/marketing-data'
import { isClient } from '@shared/utils/is-client'
import _merge from 'lodash.merge'

import { FinancialModel } from './model'

export class LoanFormDto {
	form = {
		marketingData: {
			...(isClient ? getMarketingData() : {}),
			purpose: null,
		},
		participants: [] as any[],
		facilities: [] as any[],
		calculation: {
			loan: 0,
			period: 0,
			percent: 0,
			type: 'PERCENT',
			model: 'PLATFORM',
			payment: 0,
		},
		sessionId: '',
	}

	constructor(sessionId: string) {
		this.form.sessionId = sessionId
	}

	setPurpose(purpose: string): this {
		this.form.marketingData.purpose = purpose
		return this
	}

	setCalculation(
		loan: number,
		period: number,
		percent: number,
		payment: number,
		model: FinancialModel
	): this {
		this.form.calculation.loan = loan
		this.form.calculation.period = period
		this.form.calculation.percent = percent
		this.form.calculation.payment = payment
		this.form.calculation.model = model
		return this
	}

	/**
	 * @param role служить как уникальный признак, нельзя хранить нескольких участников с одной ролью
	 * @param data данные об участнике, если передан `null` и ранее уже был участник с такой ролью то он будет удалён
	 */
	updateOrCreateParticipant(role: string, data: { [prop: string]: any } | null): this {
		const { participants } = this.form
		const curParticipantIndex = participants.findIndex((p) => p.role === role)

		if (curParticipantIndex >= 0) {
			participants[curParticipantIndex] = data
				? _merge(participants[curParticipantIndex], data)
				: participants.filter((_, i) => i !== curParticipantIndex)
		} else {
			data && participants.push({ ...data, role })
		}

		return this
	}

	/**
	 * В текущей реализации мы не может иметь больше чем один обьект в массиве `facilities`
	 * поэтому проверка на наличие уже ранее добавленного обьекта происходит просто по наличию
	 * данных по индексу 0. Так же у обьекта нет какото точно признака по которому происходит сопоставление
	 * как например role у участника
	 * @param candidate
	 */
	updateOrCreateFacility(candidate: { [prop: string]: any }): this {
		if (this.form.facilities[0]) {
			this.form.facilities[0] = _merge(this.form.facilities[0], candidate)
		} else {
			this.form.facilities[0] = candidate
		}

		return this
	}

	build() {
		return this.form
	}
}
