import React, { createContext, useCallback, useRef, useState } from 'react'
import { AnchorsOfForms } from '@constants/forms'
import { LoanFormLayout } from '@shared/components/forms/new-loan/components/layout'
import { LoanFormDto } from '@shared/components/forms/new-loan/dto'
import { FinancialModel, LoanFormProps } from '@shared/components/forms/new-loan/model'
import { useProgress } from '@shared/components/forms/new-loan/progress'
import { ProgressReturn } from '@shared/components/forms/new-loan/progress/progress'
import {
	LoanFormProcessEvents,
	loanFormProgressEvents,
} from '@shared/components/forms/new-loan/progress/progress-setup'
import { Step1 } from '@shared/components/forms/new-loan/steps/step1'
import { Step2 } from '@shared/components/forms/new-loan/steps/step2'
import { Step3 } from '@shared/components/forms/new-loan/steps/step3'
import { LoanFormSuccess } from '@shared/components/forms/new-loan/steps/success'
import { changeDocumentHash } from '@shared/pipes/dom'
import scrollToElement from '@shared/pipes/scroll'
import { AnimateSharedLayout, motion } from 'framer-motion'
import { nanoid } from 'nanoid'

interface FormProps {
	[prop: string]: any
}

export interface LoanFormCalculator {
	loan: number
	percent: number
	period: number
	payment: number
	model: FinancialModel
}

interface ContextValue {
	currentStep: number
	totalSteps: number
	progress: ProgressReturn<LoanFormProcessEvents>
	formOwnProps: LoanFormProps
	dto: LoanFormDto

	getFormData<T = FormProps>(step: number): T | null
	getCalculator(): LoanFormCalculator | null
	updateCalculator(newValue: LoanFormCalculator): void

	prevStep(formData: FormProps): void
	nextStep(formData: FormProps): void
}

const Context = createContext<ContextValue | undefined>(undefined)

const steps = {
	1: Step1,
	2: Step2,
	3: Step3,
	4: LoanFormSuccess,
}

export const LoanForm = (props: LoanFormProps) => {
	const [activeStep, setActiveStep] = useState(1)
	const progress = useProgress(loanFormProgressEvents)
	const calculator = useRef<LoanFormCalculator | null>(null)
	const [formData, setFormData] = useState<{ [step: number]: any }>({})
	const dto = useRef<LoanFormDto>(new LoanFormDto(nanoid(8)))

	const _handleChangeStep = useCallback(
		(newStep: number, formData: FormProps) => {
			setFormData((prevState) => ({
				...prevState,
				[activeStep]: formData,
			}))

			setActiveStep(newStep)
		},
		[activeStep]
	)

	progress.push(LoanFormProcessEvents.init)

	const handleGetCalculator = () => calculator.current
	const getFormData = useCallback((step: number) => formData[step] || null, [formData])
	const handleUpdateCalculator = useCallback(
		(newValue: LoanFormCalculator) => (calculator.current = newValue),
		[]
	)

	const handleNextStep = useCallback(
		(formData: FormProps) => _handleChangeStep(activeStep + 1, formData),
		[_handleChangeStep, activeStep]
	)

	const handlePrevStep = useCallback(
		(formData: FormProps) => _handleChangeStep(activeStep - 1, formData),
		[_handleChangeStep, activeStep]
	)

	const value: ContextValue = {
		progress,
		getFormData,
		dto: dto.current,
		formOwnProps: props,
		currentStep: activeStep,
		nextStep: handleNextStep,
		prevStep: handlePrevStep,
		getCalculator: handleGetCalculator,
		updateCalculator: handleUpdateCalculator,
		totalSteps: Object.values(steps).length - 1,
	}

	const StepComponent = steps[activeStep]

	return (
		<Context.Provider value={value}>
			<LoanFormLayout>
				<AnimateSharedLayout>
					<motion.div
						key={activeStep}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ delay: 0.2 }}
					>
						<StepComponent />
					</motion.div>
				</AnimateSharedLayout>
			</LoanFormLayout>
		</Context.Provider>
	)
}

export const useLoanForm = () => React.useContext(Context) as ContextValue

export const scrollToLoanForm = () => {
	scrollToElement(`#${AnchorsOfForms.loanLayout}`, {}, () => {
		changeDocumentHash(AnchorsOfForms.loanLayout)
	})
}
