import { useCallback, useEffect, useState } from 'react'
import { AnchorsOfForms } from '@constants/forms'
import { DadataAddress, DadataFio, DaDataSuggestion } from '@http/dadata/models'
import { useLoanForm } from '@shared/components/forms/new-loan'
import { LoanFormProcessEvents } from '@shared/components/forms/new-loan/progress/progress-setup'
import { useScrollTopTouchedForm } from '@shared/components/forms/new-loan/shared/hooks/use-scroll-top-touched-form'
import { sendStep3LoanForm } from '@shared/components/forms/new-loan/steps/step3/api'
import { step3InitialValues } from '@shared/components/forms/new-loan/steps/step3/initial-values'
import { Step3FormModel } from '@shared/components/forms/new-loan/steps/step3/model'
import {
	step3OwnerValidationSchema,
	step3PledgeValidationSchema,
	step3ValidationSchema,
} from '@shared/components/forms/new-loan/steps/step3/validation.schema'
import { useAsyncAutocomplete } from '@shared/hooks/use-async-autocomplete'
import {
	DadataAddressRequestRunner,
	ExternalDadataFioRequestRunner,
} from '@shared/hooks/use-async-autocomplete/request-runners'
import { useFormik } from 'formik'

import { View } from './view'

export const Step3 = () => {
	const loanForm = useLoanForm()
	useScrollTopTouchedForm(AnchorsOfForms.loanStep3, { align: { topOffset: 90 } })
	const [isSubmitting, setSubmittingStatus] = useState(false)

	const formik = useFormik<Step3FormModel>({
		validationSchema: step3ValidationSchema,
		initialValues: loanForm.getFormData<Step3FormModel>(loanForm.currentStep) || step3InitialValues,

		async onSubmit(values) {
			setSubmittingStatus(true)
			await sendStep3LoanForm(loanForm.dto, loanForm.getFormData(2)!, values).finally(() =>
				setSubmittingStatus(false)
			)
			loanForm.nextStep(values)
		},
	})

	useEffect(() => {
		loanForm.progress.event(
			LoanFormProcessEvents.fillOwner,
			step3OwnerValidationSchema.isValid(formik.values)
		)
		loanForm.progress.event(
			LoanFormProcessEvents.fillPledge,
			step3PledgeValidationSchema.isValid(formik.values)
		)
	}, [formik.values])

	const handleBack = useCallback(() => {
		loanForm.prevStep(formik.values)
	}, [formik.values])

	const [autoCompleteAddressResponse, , handleSetAddressQuery] = useAsyncAutocomplete<
		DaDataSuggestion<DadataAddress>
	>(new DadataAddressRequestRunner())

	const [autoCompleteFioResponse, , handleSetFioQuery] = useAsyncAutocomplete<
		DaDataSuggestion<DadataFio>
	>(new ExternalDadataFioRequestRunner())

	return View({
		formik,
		isSubmitting,
		onNavBack: handleBack,
		addressAutocompleteOptions: autoCompleteAddressResponse,
		handleChangeAddressAutocompleteQuery: (_, query) => handleSetAddressQuery(query),
		fioAutocompleteOptions: autoCompleteFioResponse,
		handleChangeFioAutocompleteQuery: (_, query) => handleSetFioQuery(query),
	})
}
