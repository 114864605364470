import { apiUrls } from '@http/api-urls'
import { httpService } from '@http/service'
import { transformPhoneToDto } from '@http/service/shared-transformers'
import { LoanFormCalculator } from '@shared/components/forms/new-loan'
import { LoanFormDto } from '@shared/components/forms/new-loan/dto'
import { loanFormParticipantFactory } from '@shared/components/forms/new-loan/shared/api/participant-factory'
import { Step1FormModel } from '@shared/components/forms/new-loan/steps/step1/form/model'
import { consoleDebug, isDebug } from '@shared/pipes/is-debug'

export const sendStep1LoanForm = (
	dto: LoanFormDto,
	form: Step1FormModel,
	calculator: LoanFormCalculator
): void => {
	dto
		.setCalculation(
			calculator.loan,
			calculator.period,
			calculator.percent,
			calculator.payment,
			calculator.model
		)
		.setPurpose(form.purpose!.value)
		.updateOrCreateParticipant(
			'APPLICANT',
			loanFormParticipantFactory(form.fio!, {
				email: form.email,
				phone: transformPhoneToDto(form.phone),
			})
		)

	if (isDebug()) {
		return consoleDebug('[Loan form] - prevent send form', dto.build())
	}

	httpService.post(apiUrls.addOrUpdateLead(), dto.build())
}
