import { useState } from 'react'
import { consoleDebug, isDebug } from '@shared/pipes/is-debug'
import { useFormik } from 'formik'

import { initialValues } from './initial-values'
import { IOwnProps, SimplePersonFormModel } from './model'
import { createValidationSchema } from './validation.schema'
import { View } from './view'

export const SimplePersonForm = ({
	formId,
	buttonText,
	onSubmit,
	email,
	placeholders,
	renderControls,
	onSubmitStart,
	simpleInputs,
}: IOwnProps) => {
	const [isSubmitted, setSubmitted] = useState(false)
	const [isSubmitting, setSubmitting] = useState(false)

	const form = useFormik<SimplePersonFormModel>({
		initialValues,
		validationSchema: createValidationSchema(typeof email === 'undefined'),

		async onSubmit(values) {
			setSubmitting(true)
			if (onSubmitStart) {
				onSubmitStart(values)
			}
			try {
				if (isDebug()) {
					consoleDebug('[Simple Person Form] - prevent send form', values)
				} else {
					await onSubmit(values)
				}
				setSubmitted(true)
			} catch (err) {
				console.error(err)
			} finally {
				setSubmitting(false)
			}
		},
	})

	return View({
		form,
		isSubmitting,
		isSubmitted,
		formId,
		buttonText,
		placeholders,
		renderControls,
		simpleInputs,
		email: typeof email === 'undefined',
	})
}
