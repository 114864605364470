import React from 'react'
import InputMask from 'react-input-mask'
import styled from 'styled-components'

interface InputProps {
	mask?: string | Array<string | RegExp>
	id?: string
	name?: string
	value?: string
	defaultValue?: string
	type?: string
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
	placeholder?: string
	className?: string
	hasError?: boolean
	maxLength?: number
	disabled?: boolean
}

export function Input({
	mask,
	value,
	onChange,
	onBlur,
	id,
	placeholder,
	type,
	name,
	className,
	hasError,
	maxLength,
	defaultValue,
	disabled,
}: InputProps): JSX.Element {
	if (!mask) {
		return (
			<InputStyled
				maxLength={maxLength}
				id={id}
				value={value}
				defaultValue={defaultValue}
				onChange={onChange}
				onBlur={onBlur}
				placeholder={placeholder}
				type={type}
				name={name}
				className={className}
				$hasError={!!hasError}
				disabled={disabled}
			/>
		)
	}

	return (
		<InputMaskStyled
			mask={mask}
			maskChar={null}
			maxLength={maxLength}
			id={id}
			value={value}
			defaultValue={defaultValue}
			onChange={onChange}
			onBlur={onBlur}
			placeholder={placeholder}
			type={type}
			name={name}
			className={className}
			$hasError={!!hasError}
			disabled={disabled}
		/>
	)
}

const getStyle = ({ theme, $hasError }) => ({
	display: 'block',
	width: '100%',
	borderRadius: 8,
	backgroundColor: theme.colors.lightmint,
	border: 'none',
	outline: $hasError ? `1px solid ${theme.colors.error}` : 'inherit',
	...theme.typography.text18_24,
	padding: '8px 15px',
	[theme.mediaQueries.mediaMinWidthMobile]: {
		...theme.typography.text24_32,
		padding: '16px 30px',
	},
	'&::placeholder': {
		opacity: 0.5,
	},
})

interface InputStyledProps {
	$hasError: boolean
}

const InputStyled = styled.input<InputStyledProps>(getStyle)

interface InputMaskStyledProps {
	$hasError: boolean
	maskChar: any
}

const InputMaskStyled = styled(InputMask)<InputMaskStyledProps>(getStyle)
