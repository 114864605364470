import React from 'react'
import Rub from '@shared/svg/rub.svg'

import { IStateProps } from './model'

import styles from './style.module.scss'

export const View = ({ percent, step, totalSteps }: IStateProps) => (
	<div className={styles.root}>
		<h4 className={styles.step}>{`Шаг ${step}/${totalSteps}`}</h4>
		<div className={styles.bar}>
			<div className={styles.content}>
				<Rub className={styles.icon} />
				<span>{`${percent}% Вероятность одобрения кредита`}</span>
			</div>
			<div className={styles.track} style={{ width: `${percent}%` }} />
		</div>
	</div>
)
