import { transformPhoneToDto } from '@http/service/shared-transformers'
import { SimplePersonFormModel } from '@shared/components/forms/shared/simple-person-form/model'

export const transformInvestorFormToDto = (form: SimplePersonFormModel) => {
	return {
		phone: transformPhoneToDto(form.phone),
		email: form.email,
		mailing: !!form.email,
		name: form.name,
	}
}
