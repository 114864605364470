import React from 'react'
import cx from 'classnames'

import { IStateProps } from './model'

import styles from './style.module.scss'

export const View = ({ Icon, value }: IStateProps) => (
	<div className={styles.root}>
		{Icon && <Icon />}
		<span className={cx(styles.value, { [styles.noIcon]: !Icon })}>{value}</span>
	</div>
)
