import React, { ComponentType, FunctionComponent, PropsWithChildren } from 'react'

type ProvidersWithProps = [
	FunctionComponent<PropsWithChildren>,
	PropsWithChildren<{
		[key: string]: unknown
	}>
]

type Provider = ComponentType | ProvidersWithProps

export const composeProviders =
	(providers: Provider[], layout: FunctionComponent<PropsWithChildren>) => (ownProps) =>
		providers.reduceRight((acc, curProvider) => {
			if (Array.isArray(curProvider) && curProvider.length === 2) {
				const Component = curProvider[0]
				const props = curProvider[1] as ProvidersWithProps[1]
				return <Component {...props}>{acc}</Component>
			} else {
				const Component = curProvider as FunctionComponent<PropsWithChildren>
				return <Component>{acc}</Component>
			}
		}, layout(ownProps))
