import React from 'react'
import { Button } from '@shared/components/button'

import { IStateProps } from './model'

import style from './style.module.scss'

export const View = ({ onButtonClick, buttonText }: IStateProps) => (
	<Button
		data-gtm-element='header-request-button-click'
		onClick={onButtonClick}
		size='s'
		className={style.wrap}
	>
		{buttonText}
	</Button>
)
