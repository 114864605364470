import { useEffect } from 'react'
import { AnchorsOfForms } from '@constants/forms'
import { DadataFio, DaDataSuggestion } from '@http/dadata/models'
import { useLoanForm } from '@shared/components/forms/new-loan'
import { LoanFormProcessEvents } from '@shared/components/forms/new-loan/progress/progress-setup'
import { useScrollTopTouchedForm } from '@shared/components/forms/new-loan/shared/hooks/use-scroll-top-touched-form'
import { sendStep1LoanForm } from '@shared/components/forms/new-loan/steps/step1/form/api'
import { step1InitialValues } from '@shared/components/forms/new-loan/steps/step1/form/initial-values'
import { Step1FormModel } from '@shared/components/forms/new-loan/steps/step1/form/model'
import { step1ValidationSchema } from '@shared/components/forms/new-loan/steps/step1/form/validation.schema'
import { useAsyncAutocomplete } from '@shared/hooks/use-async-autocomplete'
import { ExternalDadataFioRequestRunner } from '@shared/hooks/use-async-autocomplete/request-runners'
import { useFormik } from 'formik'

import { View } from './view'

export const LoanFormStep1 = () => {
	const loanForm = useLoanForm()

	useScrollTopTouchedForm(AnchorsOfForms.loanStep1, { align: { topOffset: 150 } }, true)

	const formik = useFormik<Step1FormModel>({
		validationSchema: step1ValidationSchema,
		initialValues: loanForm.getFormData<Step1FormModel>(loanForm.currentStep) || step1InitialValues,

		async onSubmit(values) {
			sendStep1LoanForm(loanForm.dto, values, loanForm.getCalculator()!)
			loanForm.nextStep(values)
		},
	})

	const [autoCompleteResponse, , handleSetQuery] = useAsyncAutocomplete<
		DaDataSuggestion<DadataFio>
	>(new ExternalDadataFioRequestRunner())

	useEffect(() => {
		loanForm.progress.event(LoanFormProcessEvents.fillFio, Boolean(formik.values.fio))
	}, [formik.values.fio])

	return View({
		extraHint: loanForm.formOwnProps.extraHint,
		formik,
		purposes: loanForm.formOwnProps.loanPurposes.map((value) => ({ value })),
		handleChangeFioAutocompleteQuery: (_, query) => handleSetQuery(query),
		fioAutocompleteOptions: autoCompleteResponse,
	})
}
