import { useLoanForm } from '@shared/components/forms/new-loan'

import { View } from './view'

export const LoanFormProgressBar = () => {
	const loanForm = useLoanForm()
	return View({
		step: loanForm.currentStep,
		totalSteps: loanForm.totalSteps,
		percent: loanForm.progress.value,
	})
}
