import { AutocompleteOptionForSelect } from '@shared/components/autocomplete/model'

export const typesOfMaritalStatus: AutocompleteOptionForSelect<string>[] = [
	{
		label: 'Женат/замужем',
		value: 'MARRIED',
	},
	{
		label: 'Не женат/не замужем',
		value: 'UNMARRIED',
	},
	{
		label: 'В разводе',
		value: 'DIVORCED',
	},
	{
		label: 'Вдова/вдовец',
		value: 'WIDOWED',
	},
]
