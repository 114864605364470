import React, { ComponentType } from 'react'

import { IOwnProps } from './model'
import { View } from './view'

const HeaderActionButton = ({ buttonText, onClick }: IOwnProps) => {
	return View({
		onButtonClick: onClick,
		buttonText,
	})
}

export const getHeaderActionButton =
	(ownProps: IOwnProps): ComponentType<any> =>
	(props) =>
		HeaderActionButton({ ...ownProps, ...props })
