import React from 'react'
import { LoanFormCalculator } from '@shared/components/forms/new-loan/steps/step1/calculator'
import { LoanFormStep1 } from '@shared/components/forms/new-loan/steps/step1/form'

export const View = () => (
	<>
		<LoanFormCalculator />
		<LoanFormStep1 />
	</>
)
