import { FinancialModel, PaymentType } from '@shared/components/forms/new-loan/model'
import { UseLoanCalculatorResult } from '@shared/hooks/use-loan-calculator'

import { CalculateReq } from './model'

export const transformCalculateToDto = (
	calculator: UseLoanCalculatorResult,
	paymentType: PaymentType,
	financialModel: FinancialModel
): CalculateReq[] => [
	{
		loanAmount: calculator.sum,
		loanPeriod: calculator.period,
		paymentType,
		financialModel,
		annualInterestRate: calculator.percent,
		scheduleEnabled: false,
	},
]
