import React, { PropsWithChildren, useState } from 'react'
import { InvestorFormComponent } from '@providers/investor-popup-form/form-component'
import { emptyFn } from '@shared/pipes/client'

import { Props } from './model'

interface InvestorFormPopupMethods {
	show(): void

	close(): void

	readonly isVisible: boolean
}

const InvestorFormPopupContext = React.createContext<InvestorFormPopupMethods | undefined>(
	undefined
)

export const InvestorPopupFormProvider = (props: PropsWithChildren<Props>) => {
	const [show, setVisibleStatus] = useState(false)

	const methods: InvestorFormPopupMethods = {
		show: () => setVisibleStatus(true),
		close: () => setVisibleStatus(false),
		get isVisible() {
			return show
		},
	}

	return (
		<InvestorFormPopupContext.Provider value={methods}>
			{props.children}
			<InvestorFormComponent {...props} onStartSubmitForm={props.onStartSubmitForm || emptyFn} />
		</InvestorFormPopupContext.Provider>
	)
}

export const useInvestorPopupForm = () =>
	React.useContext(InvestorFormPopupContext) as InvestorFormPopupMethods
